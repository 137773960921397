<template>
  <div>
    <CCard>
      <CCardHeader>
        <span v-if="model && model.id">編集画面</span>
        <span v-else>新規作成</span>
        <div class="card-header-actions">
          <CLink class="card-header-action btn-setting text-primary" to="."> <CIcon name="cil-chevron-left" /> キャンセル </CLink>
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <vue-form-generator :schema="schema" :model="model" :options="formOptions" />
        </CRow>

        <CAlert :show.sync="successAlertCountDown" color="success" close-button>{{ saveResult }}</CAlert>
        <CAlert :show="errors.length > 0" color="danger">
          <ul v-for="error in errors" :key="error" class="mb-0">
            <li>{{ error }}</li>
          </ul>
        </CAlert>
        <CElementCover v-if="isLoading" :opacity="0.8"></CElementCover>
      </CCardBody>
      <CCardFooter>
        <CButton color="secondary" :to="`.`"> キャンセル </CButton>
        <div class="float-right">
          <CButton v-if="model && model.id" color="primary" @click="savePharmacy()">保存</CButton>
          <CButton v-else color="primary" @click="createPharmacy()">新規作成</CButton>
        </div>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import validators from "vue-form-generator/src/utils/validators";
import { hasAuth0AdminRole } from "@/store/auth0Store";

export default {
  name: "CreateEditPharmacy",
  data() {
    return {
      isLoading: true,
      saveResult: null,
      successAlertCountDown: 0,
      errors: [],

      model: { postal_code: "", address1: "", address2: "", address3: "", address4: "", services: ["medshare"] },

      /* eslint-disable prettier/prettier */
      schema: {
        groups: [
          {
            fields: [
              { type: "checklist", label: "契約サービス", styleClasses: "col-12", model: "services", listBox: true,
                validator: [validators.array],
                values: [{ value: "medshare", name: "メドシェア" }, { value: "medorder", name: "メドオーダー" }],
              },
              { type: "input", inputType: "text", styleClasses: "col-12", label: "薬局ID", model: "id", disabled: true, visible: model => (model && model.id) },
              { type: "input", inputType: "text", styleClasses: "col-12", label: "薬局会社ID", model: "company_id", disabled: true, visible: model => (model && model.id) },
              { type: "input", inputType: "text", styleClasses: "col-12", label: "薬局名", model: "name", required: true, validator: [validators.string] },
              {
                type: "input", inputType: "text", styleClasses: "col-12", label: "医療機関コード(MICODE)", model: "micode",
                required: true, min: 10, max: 10, validator: [validators.string],
              },
              { type: "input", inputType: "text", styleClasses: "col-12", label: "登録番号", model: "pharmacy_invoice.invoice_number" },
              {
                type: "input", inputType: "text", styleClasses: "col-12", label: "郵便番号", model: "postal_code", hint: "101-0051",
                required: true, validator: [validators.string],
                onChanged: this.onChangedPostalCode,
                buttons: [
                  { label: "住所を自動設定", onclick: this.setAddressesByPostalCode },
                ]
              },
              { type: "input", inputType: "text", styleClasses: "col-md-3 col-12", label: "住所1（都道府県）", model: "address1", hint: "東京都", required: true, validator: [validators.string] },
              { type: "input", inputType: "text", styleClasses: "col-md-3 col-12", label: "住所2（市区郡）", model: "address2", hint: "千代田区", required: true, validator: [validators.string] },
              { type: "input", inputType: "text", styleClasses: "col-md-3 col-12", label: "住所3（町村・字）", model: "address3", hint: "神田神保町", required: true, validator: [validators.string] },
              { type: "input", inputType: "text", styleClasses: "col-md-3 col-12", label: "住所4（それ以降）", model: "address4", hint: "1-2-3 水野ビル3階", validator: [validators.string] },
              { type: "input", inputType: "text", styleClasses: "col-6", label: "緯度", model: "latitude",  disabled: true, hint: "住所から自動算出", visible: model => (model && model.id) },
              { type: "input", inputType: "text", styleClasses: "col-6", label: "経度", model: "longitude", disabled: true, hint: "住所から自動算出", visible: model => (model && model.id) },
              { type: "input", inputType: "text", styleClasses: "col-6", label: "電話", model: "phone", required: true, hint: "03-6273-7634", validator: [validators.string] },
              { type: "input", inputType: "text", styleClasses: "col-6", label: "FAX", model: "fax", hint: "03-6273-7584", validator: [validators.string] },
              { type: "input", inputType: "email", styleClasses: "col-12", label: "メールアドレス", model: "email", required: true, hint: "support@pharsas.com", validator: [validators.email] },
              { type: "input", inputType: "text", styleClasses: "col-12", label: "WEB URL", model: "web_url", hint: "https://www.pharmacloud.co.jp/", validator: [validators.url] },
              { type: "input", inputType: "text", styleClasses: "col-12", label: "地理案内", model: "navigation", hint: "うどんの丸香から徒歩1分", validator: [validators.string] },
              { type: "select", styleClasses: "col-12", label: "予測モデル", model: "predict_config.predictor", values: [ {name: "予測 v1 最適化ロジック", id:"optimized_orders"}, {name: "予測 v2 統計的ロジック", id:"statistic"}, {name: "予測 ロジック3", id:"model_3"} ], selectOptions: { noneSelectedText: 'not selected' }, visible: model => hasAuth0AdminRole },
              { type: "textArea", styleClasses: "col-12", label: "ノート", model: "note", rows: 4, validator: [validators.string] },
              { type: "input", inputType: "date", styleClasses: "col-md-4 col-12", label: "運用開始日", model: "started_on" },
              { type: "input", inputType: "date", styleClasses: "col-md-4 col-12", label: "運用終了日", model: "ended_on" },
              { type: "input", inputType: "date", styleClasses: "col-md-4 col-12", label: "削除日", model: "deleted_on" },
            ],
          },
        ],
      },
      /* eslint-enable prettier/prettier */

      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: false,
      },
    };
  },
  created() {
    const pharmacyId = this.$route.params.id;
    if (pharmacyId) {
      this.fetchPharmacy(pharmacyId);
      this.formOptions.validateAfterLoad = true;
      this.formOptions.validateAfterChanged = true;
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    async fetchPharmacy(pharmacyId) {
      if (pharmacyId) {
        this.model = (await this.$axios.get(`/api/v2/core/admin/pharmacies/${pharmacyId}`)).data;

        this.isLoading = false;
      }
    },
    async createPharmacy() {
      try {
        this.isLoading = true;
        this.errors = [];
        this.formOptions.validateAfterLoad = true;
        this.formOptions.validateAfterChanged = true;

        if (!this.model.predict_config?.predictor) {
          this.errors = ["予測モデルを選択してください。"];
          return;
        }

        if (this.model.services.length === 0) {
          // 薬局の作成時だけは、「契約サービス」を必須とする。
          this.errors = ["契約サービスを少なくとも1つは選んでください。"];
          return;
        }

        if (typeof this.model.started_on === "number") {
          const date = new Date(this.model.started_on);
          this.model.started_on = date.toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" });
        }

        if (typeof this.model.ended_on === "number") {
          const date = new Date(this.model.ended_on);
          this.model.ended_on = date.toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" });
        }

        if (typeof this.model.deleted_on === "number") {
          const date = new Date(this.model.deleted_on);
          this.model.deleted_on = date.toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" });
        }

        await this.convertAvailableServices();
        await this.convertPredictConfig();
        await this.convertInvoiceNumber();
        const response = (await this.$axios.post("/api/v2/core/admin/pharmacies", this.model)).data;
        this.saveResult = "新規作成しました。";
        this.successAlertCountDown = 3;
        await this.$router.push({ name: "薬局情報", params: { id: response.id } });
      } catch (error) {
        this.errors = error.response.data.errors;
      } finally {
        this.isLoading = false;
      }
    },
    async savePharmacy() {
      try {
        this.isLoading = true;
        this.errors = [];

        if (!this.model.predict_config?.predictor) {
          this.errors = ["予測モデルを選択してください。"];
          return;
        }

        if (typeof this.model.started_on === "number") {
          const date = new Date(this.model.started_on);
          this.model.started_on = date.toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" });
        }

        if (typeof this.model.ended_on === "number") {
          const date = new Date(this.model.ended_on);
          this.model.ended_on = date.toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" });
        }

        if (typeof this.model.deleted_on === "number") {
          const date = new Date(this.model.deleted_on);
          this.model.deleted_on = date.toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" });
        }

        await this.convertAvailableServices();
        await this.convertPredictConfig();
        await this.convertInvoiceNumber();
        await this.$axios.put(`/api/v2/core/admin/pharmacies/${this.model.id}`, this.model);
        await this.fetchPharmacy(this.model.id);
        this.saveResult = "更新成功しました。";
        this.successAlertCountDown = 3;
      } catch (error) {
        this.errors = error.response.data.errors;
      } finally {
        this.isLoading = false;
      }
    },
    async convertAvailableServices() {
      const services = { medshare: false, medorder: false };
      this.model.services.forEach((service) => (services[service] = true));
      this.model.service_attributes = services;
    },
    async convertPredictConfig() {
      this.model.predict_config_attributes = { predictor: this.model.predict_config.predictor };
    },
    async convertInvoiceNumber() {
      if (this.model.pharmacy_invoice) {
        this.model.pharmacy_invoice_attributes = { invoice_number: this.model.pharmacy_invoice.invoice_number };
      }
    },
    /*
     * utility methods
     */
    async onChangedPostalCode(model) {
      if (!model.address1 && !model.address2 && !model.address3) {
        await this.setAddressesByPostalCode(model);
      }
    },
    async setAddressesByPostalCode(model) {
      try {
        this.isLoading = true;

        if (!/[0-9]{7}/.test(model.postal_code.replace("-", ""))) return;

        const req_params = { postal_code: model.postal_code };
        const response = (await this.$axios.get("/api/v2/core/master/postal_codes/search", { params: req_params })).data;
        if (response && response.results && response.results[0]) {
          model.address1 = response.results[0]["address1"];
          model.address2 = response.results[0]["address2"];
          model.address3 = response.results[0]["address3"];
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
